import { filter, flatMap, isEmpty, map, partial, reduce } from 'lodash';

import { Profile } from '@zapier/shared-entities';
import { RouterSelectors } from '@zapier/toolbox-redux-framework';
import { singular } from '@zapier/common-utils';

import * as AppAdmin from 'app/entities/AppAdmin';
import * as CliApp from 'app/entities/CliApp';
import * as CliAppDefinition from 'app/entities/CliAppDefinition';
import * as EnvironmentVariable from 'app/entities/EnvironmentVariable';
import { SubmitStatus } from 'app/developer-v3/types/statuses';
import {
  APP_ACTIONS,
  BASIC_AUTH_FIELDS,
  FORM_TYPES,
  OAUTH2_DATA,
  SERVICE_TYPE,
  rootKey,
} from 'app/developer-v3/constants';
import {
  createBundleItem,
  getAppVersionUrl,
  getMetaFields,
  getServiceUrl,
  getTriggerBundle,
  getValidAppIntention,
  isAuthSetupComplete,
  mergeFields,
} from 'app/developer-v3/utils';

import type { State } from 'app/common/types';
import type { TriggerSchema } from 'app/developer-v3/platformSchema/trigger';
import type { ServiceType } from 'app/developer-v3/types/service';
import { AppDefinitionSchema } from 'app/developer-v3/platformSchema/app-definition';
import { RequestSchema } from './platformSchema/request';

const {
  actions: ACTIONS,
  triggers: TRIGGERS,
  searchOrCreates: SEARCH_OR_CREATES,
} = SERVICE_TYPE;

// Prefer the current integration ID in the URL to the potentially stale ID in
// the redux store.
export const currentAppId = (state: State) =>
  RouterSelectors.getParams(state)?.appId || state?.[rootKey]?.currentApp?.id;

// Use the *latest* version for the /app/:id route (partner program page) or the
// potentially stale version in the redux store for other routes.
export const currentAppVersion = (state: State) => {
  const params = RouterSelectors.getParams(state);

  // Some tests don't have access to a router, so `params` will be
  // undefined and we return the default version here as a workaround.
  if (!params) {
    return '1.0.0';
  }

  return state?.[rootKey]?.currentApp?.version || '1.0.0';
};

export const cleanDefinition = (state: State) =>
  state?.[rootKey]?.cleanDefinition;

export const formFields = (state: State, formId: FORM_TYPES) =>
  state?.[rootKey]?.formFields?.[formId] ?? [];

export const currentActiveTestTab = (state: State) =>
  state?.[rootKey]?.currentActiveTestTab ?? 0;

export const currentTestRequest = (state: State) =>
  state?.[rootKey]?.testRequest ?? {};

export const currentTestLogs = (state: State) => state?.[rootKey]?.testLogs;

export const hasUnsavedChanges = (state: State) =>
  state?.[rootKey]?.hasUnsavedChanges ?? false;

export const lifecycleHasStarted = (state: State): boolean =>
  state?.[rootKey]?.versions?.lifecycle?.started;
export const lifecycleHasFinished = (state: State): boolean =>
  state?.[rootKey]?.versions?.lifecycle?.success;
export const lifecycleErrorMessage = (state: State): string =>
  state?.[rootKey]?.versions?.lifecycle?.errorMessage;
export const lifecycleHasFailed = (state: State) =>
  Boolean(lifecycleErrorMessage(state));

export const currentAppDefinitionEntityId = (state: State) => {
  const appDefinition = CliAppDefinition.selectors.all.findByAppIdAndVersion(
    currentAppId(state),
    currentAppVersion(state),
    state
  );
  return CliAppDefinition.selectors.id(appDefinition);
};

export const currentDefinition = (state: State) => {
  const appDefinition = CliAppDefinition.selectors.all.findByAppIdAndVersion(
    currentAppId(state),
    currentAppVersion(state),
    state
  );
  return CliAppDefinition.selectors.definition(appDefinition);
};

export const currentDefinitionOverride = (state: State) => {
  const appDefinition = CliAppDefinition.selectors.all.findByAppIdAndVersion(
    currentAppId(state),
    currentAppVersion(state),
    state
  );
  return CliAppDefinition.selectors.definitionOverride(appDefinition);
};

export const currentDefinitionOverrideClean = (state: State) => {
  const appDefinition = CliAppDefinition.selectors.all.findByAppIdAndVersion(
    currentAppId(state),
    currentAppVersion(state),
    state
  );
  return CliAppDefinition.selectors.definitionOverrideClean(appDefinition);
};

export const currentAppEntity = (state: State) => {
  const entityId = currentAppId(state);
  return CliApp.selectors.all.entity(entityId, state);
};

export const currentAppDefinitionEntity = (state: State) => {
  const entityId = currentAppDefinitionEntityId(state);
  return CliAppDefinition.selectors.all.entity(entityId, state);
};

export const currentAppTitle = (state: State) => {
  const entity = currentAppEntity(state);
  return CliApp.selectors.title(entity);
};

export const currentSelectedApi = (state: State) => {
  const entity = currentAppDefinitionEntity(state);
  return CliAppDefinition.selectors.selectedApi(entity);
};

export const currentServiceKey = (type: SERVICE_TYPE, state: State) => {
  const serviceTypeKey = `${singular(type)}Key`;
  const { [serviceTypeKey]: serviceKey, actionKey } = RouterSelectors.getParams(
    state
  );

  return actionKey || serviceKey;
};

export const currentLegacyService = (type: SERVICE_TYPE, state: State) => {
  const serviceKey = currentServiceKey(type, state);
  const serviceType =
    type === SERVICE_TYPE.actions
      ? RouterSelectors.getParam('actionType', state)
      : type;
  return currentDefinitionOverride(state)?.legacy?.[serviceType]?.[serviceKey];
};

export const currentLegacyTrigger = partial(currentLegacyService, TRIGGERS);
export const currentLegacyAction = partial(currentLegacyService, ACTIONS);
export const currentLegacySearchOrCreates = partial(
  currentLegacyService,
  SEARCH_OR_CREATES
);

export const currentService = (type: SERVICE_TYPE, state: State) => {
  const serviceKey = currentServiceKey(type, state);
  const serviceType =
    type === SERVICE_TYPE.actions
      ? RouterSelectors.getParam('actionType', state)
      : type;
  return currentDefinitionOverride(state)?.[serviceType]?.[serviceKey] ?? {};
};

export const currentServiceClean = (type: SERVICE_TYPE, state: State) => {
  const serviceKey = currentServiceKey(type, state);
  const serviceType =
    type === SERVICE_TYPE.actions
      ? RouterSelectors.getParam('actionType', state)
      : type;
  return (
    currentDefinitionOverrideClean(state)?.[serviceType]?.[serviceKey] ?? {}
  );
};

export const currentTrigger = partial(currentService, TRIGGERS);
export const currentTriggerClean = partial(currentServiceClean, TRIGGERS);
export const currentAction = partial(currentService, ACTIONS);
export const currentActionClean = partial(currentServiceClean, ACTIONS);
export const currentSearchOrCreates = partial(
  currentService,
  SEARCH_OR_CREATES
);

export const currentServiceType = (state: State) => {
  const { serviceType, actionType } = RouterSelectors.getParams(state);

  return actionType || serviceType;
};

export const currentServiceInputFields = (
  type: ServiceType | typeof ACTIONS,
  state: State
) => currentService(type, state)?.operation?.inputFields ?? [];

export const appActions: (state: State) => AppDefinitionSchema = state => {
  // Godzilla versions will have a definition override, while CLI versions will have a definition. Since the default
  // for the other is an empty object, they can be concatenated safely.
  const definition = {
    ...currentDefinitionOverride(state),
    ...currentDefinition(state),
  };

  if (isEmpty(definition)) {
    return {};
  }

  return reduce(
    APP_ACTIONS,
    (actions, service) => {
      if (isEmpty(definition[service])) return actions;

      actions[service] = map(definition[service], action => action);
      return actions;
    },
    {}
  );
};

export const appTriggers: (state: State) => Array<TriggerSchema> = state => {
  // Godzilla versions will have a definition override, while CLI versions will have a definition. Since the default
  // for the other is an empty object, they can be concatenated safely.
  const definition = {
    ...currentDefinitionOverride(state),
    ...currentDefinition(state),
  };
  return Object.values(definition.triggers || {});
};

export const isCurrentAppDefinitionSaving = (state: State) => {
  const entity = currentAppDefinitionEntity(state);
  return CliAppDefinition.selectors.isSaving(entity);
};

export const hasCurrentAppDefinitionFailed = (state: State) => {
  const entity = currentAppDefinitionEntity(state);
  return CliAppDefinition.selectors.isFailure(entity);
};

export const isCurrentAppSaving = (state: State) => {
  const entity = currentAppEntity(state);
  return CliApp.selectors.isSaving(entity);
};

export const currentAppVersionEntities = (state: State) => {
  const appId = currentAppId(state);
  const appEntity = currentAppEntity(state);

  const versionNumbers: Array<string> = CliApp.selectors.versions(appEntity);
  if (!versionNumbers.length) {
    return [];
  }

  return versionNumbers
    .map(versionNumber => {
      return CliAppDefinition.selectors.all.findByAppIdAndVersion(
        appId,
        versionNumber,
        state
      );
    })
    .filter(Boolean);
};

/**
 * Returns a list of version numbers that have had their Bouncer reviews
 * pushed-back by an app reviewer.
 */
export const selectPushedBackVersions = (state: State): Array<{}> => {
  const versionEntities = currentAppVersionEntities(state);
  return versionEntities
    .filter(entity => {
      return (
        CliAppDefinition.selectors.submitStatus(entity) ===
        SubmitStatus.pushedBack
      );
    })
    .map(CliAppDefinition.selectors.version);
};

export const currentAppVersionEntity = (state: State) => {
  const appVersion = currentAppVersion(state);
  const versionEntities = currentAppVersionEntities(state);
  return versionEntities
    .map(versionEntity => ({
      isGUIIntegration: !!CliAppDefinition.selectors.isGUIIntegration(
        versionEntity
      ),
      version: CliAppDefinition.selectors.version(versionEntity),
    }))
    .find(v => v.version === appVersion);
};

export const availableTriggers = (state: State) => {
  const currentTriggerKey = RouterSelectors.getParam('triggerKey', state);
  const triggers = currentDefinitionOverride(state).triggers || {};

  return filter(
    triggers,
    (trigger: TriggerSchema) => trigger.key !== currentTriggerKey
  );
};

export const allServicesByType = (serviceType: ServiceType, state: State) =>
  currentDefinitionOverride(state)?.[serviceType] ?? {};

export const currentServicesListUrl = (type: ServiceType, state: State) => {
  const appId = currentAppId(state);
  const versionNumber = currentAppVersion(state);
  const { actionType } = RouterSelectors.getParams(state);
  const serviceRoute = actionType ? ACTIONS : type;

  return `${getAppVersionUrl(appId, versionNumber)}/${serviceRoute}`;
};

export const currentServiceUrl = (type: ServiceType, state: State) => {
  const id = currentAppId(state);
  const version = currentAppVersion(state);
  const params = RouterSelectors.getParams(state);

  return getServiceUrl(type, { id, version, ...params });
};

export const currentAppAuthentication = (state: State) => {
  const appDefinitionOverride = currentDefinitionOverride(state);
  return appDefinitionOverride?.authentication ?? [];
};

export const currentAuthFields = (state: State) => {
  const appDefinitionOverride = currentDefinitionOverride(state);
  return appDefinitionOverride?.authentication?.fields ?? [];
};

export const currentAuthType = (state: State) => {
  const appDefinitionOverride = currentDefinitionOverride(state);
  return appDefinitionOverride?.authentication?.type;
};

export const versionEnvironmentVariables = (state: State) => {
  const vars = EnvironmentVariable.selectors.all.environmentVariablesForVersion(
    currentAppId(state),
    currentAppVersion(state),
    state
  );

  const mapped = vars.map(EnvironmentVariable.selectors.data);
  return mapped;
};

const getAuthBundleHander = type => {
  const authHandlers = {
    oauth2: (fields, methodName) => {
      const allOauthFields = mergeFields(fields, OAUTH2_DATA);
      const useInputData = [
        'authorizeUrl',
        'getAccessToken',
        'refreshAccessToken',
      ];

      const bundleKey = useInputData.includes(methodName)
        ? 'inputData'
        : 'authData';

      return { [bundleKey]: allOauthFields };
    },
    basic: fields => ({
      authData: mergeFields(fields, BASIC_AUTH_FIELDS),
    }),
    digest: fields => ({
      authData: mergeFields(fields, BASIC_AUTH_FIELDS),
    }),
    default: fields => ({ authData: fields }),
  };

  return authHandlers[type] || authHandlers.default;
};

const getAuthBundle = (requestType: string, state: State) => {
  const authType = currentAuthType(state);
  const userFields = currentAuthFields(state);
  const authHandler: (fields, requestType) => {} = getAuthBundleHander(
    authType
  );

  return authType ? authHandler(userFields, requestType) : {};
};

const getApiBundleHandler = (
  serviceType: ServiceType | typeof ACTIONS,
  state: State
) => {
  const { operation } = currentService(serviceType, state);

  const apiHandlers = {
    triggers: requestType => {
      return getTriggerBundle(operation?.type === 'polling', requestType);
    },
    default: () => ({}),
  };

  return apiHandlers[serviceType] || apiHandlers.default;
};

const getApiBundle = (serviceType: ServiceType) => (
  requestType: string,
  state: State
) => {
  const apiHandler: (requestType?: string) => {} = getApiBundleHandler(
    serviceType,
    state
  );
  return {
    inputData: currentServiceInputFields(serviceType, state),
    ...apiHandler(requestType),
    ...getAuthBundle('', state),
  };
};

const getBundleHandler = (serviceType: ServiceType | typeof ACTIONS) => {
  const bundleHandlers = {
    authentication: getAuthBundle,
    default: getApiBundle,
  };

  return bundleHandlers[serviceType] || bundleHandlers.default(serviceType);
};

export const currentPlatformVariablesList = (
  serviceType: ServiceType | typeof ACTIONS,
  state: State
) => (requestType: string) => {
  const envVars = versionEnvironmentVariables(state).map(
    (envVar: Record<string, any>) => `process.env.${envVar.key}`
  );

  const addBundleFields = getBundleHandler(serviceType);

  const bundle = {
    meta: getMetaFields(requestType),
    ...addBundleFields(requestType, state),
  };

  const bundleVars = flatMap(bundle, (values, bundleKey) =>
    map(values, item => createBundleItem(bundleKey, item.key))
  );

  return [...envVars, ...bundleVars];
};

export const selectIsAuthSetupComplete = (state: State) => {
  const auth = currentAppAuthentication(state);

  return isAuthSetupComplete(auth);
};

export const selectIsServiceSetup = (state: State) => {
  const serviceType = currentServiceType(state);
  const service = currentService(serviceType, state);
  const { url, source } = service?.operation?.perform ?? {};
  return !!(url || source);
};

export const selectTasks = (state: State) => {
  return state?.[rootKey]?.tasks;
};

export const selectZapTaskDetails = (state: State) => {
  return state?.developerV3?.currentApp?.zapDetails;
};

export const selectWbAppId = (state: State) => {
  return (
    state?.developer?.currentAppId || state?.developer?.currentCliAppVersion
  );
};

export const selectAppIdOrWb = (state: State) => {
  const v3AppId = currentAppId(state);
  const wbId = selectWbAppId(state);

  return {
    appId: v3AppId || wbId,
    isWb: !!wbId,
  };
};

// A little confusing, but this is for when someone is viewing a v3 CLI app within WB.
// Can be removed once WB is deleted.
export const selectWbCliAppVersion = (state: State) => {
  return state?.developer?.currentCliAppVersion;
};

export const selectAppVersionOrWb = (state: State) => {
  return currentAppVersion(state) || selectAppVersionOrWb(state);
};

export const isNotAdmin = (state: State) => {
  const currentProfile = Profile.selectors.all.currentProfile(state);
  const isStaff = Profile.selectors.isStaff(currentProfile);
  const currentProfileId = Profile.selectors.all.currentProfileId(state);
  const admins = AppAdmin.selectors.all.persistedEntitiesData(state);
  // The prefix 'a' stands for accepted and 'w' stands for waiting.
  return !isStaff && !admins.find(admin => admin.id === `a${currentProfileId}`);
};

export const isStaff = (state: State) => {
  const currentProfile = Profile.selectors.all.currentProfile(state);
  return Profile.selectors.isStaff(currentProfile);
};

export const isCurrentAppIntentionPublic = (state: State) => {
  const appEntity = currentAppEntity(state);
  const intention = appEntity?.data
    ? getValidAppIntention(appEntity.data.intention)
    : 'global';
  return intention === 'global';
};

export const currentRequestTemplate = (state: State): RequestSchema => {
  const appDefinitionOverride = currentDefinitionOverride(state);
  return appDefinitionOverride?.requestTemplate ?? {};
};

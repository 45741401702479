import { useSplitTreatments } from '@splitsoftware/splitio-react';

export default function useFeatureFlag(flag: string, key?: string) {
  const { isReady, treatments } = useSplitTreatments({
    names: [flag],
    splitKey: key,
  });

  return { isOn: treatments[flag].treatment === 'on', isReady };
}
